<template>
    <div class="addERC20-wrap">
        <CRow>
            <CCard class="cardERC-form">
                <CCardBody>
                    <ValidationObserver ref="observer" @submit.prevent="userAction">
                        <CForm>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_PLATFORM')" rules="required" v-slot="{ errors }">
                                <div class="select-wrap form-group">
                                    <label>{{$t("ADD_ERC20_PAGE_LABEL_PLATFORM")}}</label>
                                    <select class="form-control role-select" v-model="platform.platform">
                                        <option
                                                v-for="option in options"
                                                v-bind:value="option.symbol"
                                                :key="option.symbol"
                                        >{{ option.symbol }}</option>
                                    </select>
                                </div>
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </ValidationProvider>
                            <div class="form-group file-area-group">
                                <!-- <label for="icon"></label> -->
                                <div class="file-area">
                                    <img :src="srcImg" alt="">
                                    <div class="ip-file-group">
                                        <label for="images">+ {{$t("ADD_ERC20_PAGE_BUTTON_ADD_LOGO")}}</label>
                                        <input type="file" name="icon" id="images" @change="uploadImage" />
                                    </div>
                                    <p class="label-file-support">{{$t("ADD_ERC20_PAGE_LABEL_FILE_SUPPORT")}}</p>
                                </div>
                                <p class="msg-err-img" v-if="imageError===true">{{$t("ADD_ERC20_PAGE_IMAGE_OVER_SIZE")}}</p>
                            </div>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_NAME')" rules="required|max:20" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_TOKEN_NAME')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_NAME')"
                                            horizontal
                                            v-model="platform.name"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_CODE')" rules="required|max:8" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_TOKEN_CODE')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_CODE')"
                                            horizontal
                                            v-model="platform.symbol"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_DECIMAL')" rules="required|min_value:0" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_TOKEN_DECIMAL')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_DECIMAL')"
                                            type="number"
                                            horizontal
                                            v-model="platform.decimal"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS')" :rules="getRules()" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_TOKEN_ADDRESS')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_ADDRESS')"
                                            horizontal
                                            v-model="platform.sc_token_address"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_VALIDATOR_FEE')" rules="required|min_value:0|max_value:1000" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_VALIDATOR_FEE')"
                                            placeholder="0"
                                            type="number"
                                            horizontal
                                            v-model="platform.erc20_validator_fee"
                                            class="form-group--custom est-per"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_EST_REWARD')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_REWARD')"
                                            placeholder="0"
                                            horizontal
                                            v-model="platform.erc20_reward_estimate"
                                            class="form-group--custom sec-label"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("ADD_ERC20_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_DURATION')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_DURATION')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_DURATION')"
                                            horizontal
                                            v-model="platform.erc20_duration"
                                            class="form-group--custom sec-label"
                                    />
                                    <label class="label-display-only col-sm-3">{{$t("ADD_ERC20_PAGE_LABEL_DISPLAY_ONLY")}}</label>
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_MAX_PAYOUT')" rules="required" v-slot="{ errors }">
                                <div>
                                    <CInput
                                            :label="$t('ADD_ERC20_PAGE_LABEL_MAX_PAYOUT')"
                                            :placeholder="$t('ADD_ERC20_PAGE_PLACEHOLDER_MAX_PAYOUT')"
                                            horizontal
                                            v-model="platform.max_payout"
                                            class="form-group--custom"
                                    />
                                    <CRow>
                                        <CCol :sm="{offset:'3'}"></CCol>
                                        <CCol :sm="9">
                                            <p class="error-msg">{{errors[0]}}</p>
                                        </CCol>
                                    </CRow>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider :name="$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_STATUS')" rules="required" v-slot="{ errors }">
                                <div class="select-wrap form-group">
                                    <label>{{$t("ADD_ERC20_PAGE_LABEL_STATUS")}}</label>
                                    <select class="form-control role-select" v-model="platform.status">
                                        <option
                                            v-for="item in status"
                                            v-bind:value="item"
                                            :key="item"
                                        >
                                            {{$t(`ADD_ERC20_PAGE_OPTION_SELECT_${renderStatus(item)}`)}}
                                        </option>
                                    </select>
                                </div>
                                <CRow>
                                    <CCol :sm="{offset:'3'}"></CCol>
                                    <CCol :sm="9">
                                        <p class="error-msg">{{errors[0]}}</p>
                                    </CCol>
                                </CRow>
                            </ValidationProvider>
                            <div class="btn-ERC20">
                                <!-- <button class="erc-cancel">Cancel</button> -->
                                <CButton type="submit">{{$t("ADD_ERC20_PAGE_BUTTON_SAVE")}}</CButton>
                            </div>
                        </CForm>
                    </ValidationObserver>
                </CCardBody>
            </CCard>
        </CRow>
    </div>
</template>
<script>
    import Draggable from "vuedraggable";
    import endpoints from "@/constants/endpoints";
    import { mapState, mapActions } from "vuex";
    export default {
        components: {
            Draggable
        },
        data() {
            return {
                platform: {},
                options: [],
                icon: '',
                srcImg:'',
                imageError: false,
                status:[
                    1,
                    -1,
                    -2,
                    0,
                ],
            };
        },
        computed: {
        },
        async mounted() {
            await this.getPlatforms();
        },
        methods: {
            ...mapActions([
                "AddERC20",
            ]),
            async getPlatforms() {
                let result = await this.$http.get(
                    endpoints.getStakingPlatformConfig,
                );
                if (result.data) {
                    this.options = result.data
                }
            },
            uploadImage(event) {
                const image = event.target.files[0];
                this.imageError=false;
                if(image.size>300000 && (image.type!=='image/png' || image.type!=='image/jpg' || image.type!=='image/svg' || image.type!=='image/jpeg')){
                    this.imageError=true;
                    this.srcImg='';
                    return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = e => {
                    this.icon = image;
                    this.srcImg = e.target.result;
                };
            },
            async userAction() {
                const isValid = await this.$refs.observer.validate();
                if(!isValid) return;
                const payload = {
                    ...this.platform,
                    icon: this.icon
                }
                const response = await this.AddERC20(payload);
                if (response) {
                    this.$router.back()
                }
            },
            renderStatus(item) {
                if (item === -2) {
                    return 'HIDDEN'
                }
                if (item === -1) {
                    return 'DISABLED'
                }
                if (item === 0) {
                    return 'COMING_SOON'
                }
                if (item === 1) {
                    return 'ENABLED'
                }
            },
            getRules() {
                return {
                    required: true,
                    regex: /^0x[0-9a-fA-F]{40}$/
                };
            },
        },
    };
</script>
<style lang="scss">
    .addERC20-wrap{
        .row{
            margin: 0;
            padding: 0 24px;
            .cardERC-form{
                width: 70%;
                @media only screen and ( max-width: 1024px ){
                    width: 100%;
                }
                .card-body{
                    padding: 42px 15px;
                    .form-group{
                        display: flex;
                        flex-wrap: nowrap;
                        align-items: center;
                        justify-content: flex-start;
                        margin-bottom: 5px;
                        margin-top: 1rem;
                        label{
                            width: 25%;
                            text-align: right;
                            padding-right: 15px;
                            line-height: 25px;
                            color: #3E4B65;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                        }
                        .form-control{
                            width: 55%;
                            @media only screnn and ( max-width: 1024px ){
                                width: 75%;
                            }
                        }
                        &.file-area-group{
                            display: flex;
                            flex-wrap: wrap;
                            .file-area{
                                display: flex;
                                width: 80%;
                                padding-left: 25%;
                                align-items: flex-start;
                                img:not([src=""]){
                                    width: 60px;
                                    align-self: center;
                                    margin-right: 10px;
                                }
                                .ip-file-group{
                                    position: relative;
                                    width: 60px;
                                    min-width: 60px;
                                    height: 60px;
                                    margin-right: 10px;
                                    label{
                                        position: absolute;
                                        width: 100%;
                                        height: 100%;
                                        padding: 5px 10px;
                                        /* font-family: "SF UI Display"; */
                                        font-size: 14px;
                                        font-weight: 500;
                                        letter-spacing: 0.5px;
                                        line-height: 16px;
                                        text-align: center;
                                        margin: 0;
                                        border-radius: 4px;
                                        background: #D8D8D8;
                                    }
                                    #images{
                                        position: absolute;
                                        width: 60px;
                                        height: 60px;
                                        opacity: 0;
                                    }
                                }
                                .label-file-support{
                                    color: #8A93A2;
                                    // font-family: "SF UI Display";
                                    font-size: 10px;
                                    font-weight: 500;
                                    line-height: 16px;
                                    margin: 0;
                                }
                            }
                            .msg-err-img{
                                width: 80%;
                                color: #ee0d0d;
                                // font-family: "SF UI Display";
                                font-size: 14px;
                                font-weight: 300;
                                line-height: 16px;
                                margin: 10px 0 0 0;
                                padding-left: 25%;
                            }
                        }
                    }
                    .form-group--custom{
                        .col-form-label{
                            text-align: right;
                            padding: 0 15px;
                        }
                        .col-sm-9{
                            flex: 0 0 55%;
                            max-width: 55%;
                            input{
                                width: 100%;
                                &[type="number"]{
                                    width: 65px;
                                    &::after{
                                        content: '%';
                                        position: relative;
                                    }
                                }
                            }
                        }
                        &.est-per{
                            .col-sm-9::after{
                                content: '%';
                                position: absolute;
                                font-size: 14px;
                                font-weight: 300;
                                left: 85px;
                                top: 0;
                                line-height: 35px;
                            }
                        }
                        &.sec-label{
                            label{
                                top: -10px;
                                display: flex;
                                flex-direction: column;
                            }
                        }
                    }
                    .label-display-only {
                        font-size: 12px;
                        font-weight: 300;
                        color: #8A93A2;
                        width: 100%;
                        letter-spacing: 0.5px;
                        bottom: 20px;
                        text-align: right;
                    }
                    .row{
                        padding: 0;
                        .col-sm-9{
                            padding: 0;
                            .error-msg{
                                color: #ff0000;
                                margin: 0;
                            }
                        }
                    }
                    .btn-ERC20{
                        width: 100%;
                        margin: 35px 0 0 0;
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 50px;
                        .erc-cancel{
                            color: #657187;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            text-align: center;
                            background: unset;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            margin-right: 50px;
                        }
                        button[type="submit"]{
                            min-width: 146px;
                            height: 36px;
                            border-radius: 4px;
                            background-color: #3B4B7E;
                            color: #FFFFFF;
                            // font-family: "SF UI Display";
                            font-size: 14px;
                            font-weight: 500;
                            letter-spacing: 0.5px;
                            border: none;
                            outline: none !important;
                            box-shadow: none;
                            text-align: center;
                        }
                    }
                }
            }
            .col--padding{
                padding: 0;
                .card-body{
                    overflow-x: auto;
                }
            }
        }
    }
</style>