var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addERC20-wrap"},[_c('CRow',[_c('CCard',{staticClass:"cardERC-form"},[_c('CCardBody',[_c('ValidationObserver',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.userAction($event)}}},[_c('CForm',[_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_PLATFORM'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"select-wrap form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_LABEL_PLATFORM")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.platform.platform),expression:"platform.platform"}],staticClass:"form-control role-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.platform, "platform", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.options),function(option){return _c('option',{key:option.symbol,domProps:{"value":option.symbol}},[_vm._v(_vm._s(option.symbol))])}),0)]),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('div',{staticClass:"form-group file-area-group"},[_c('div',{staticClass:"file-area"},[_c('img',{attrs:{"src":_vm.srcImg,"alt":""}}),_c('div',{staticClass:"ip-file-group"},[_c('label',{attrs:{"for":"images"}},[_vm._v("+ "+_vm._s(_vm.$t("ADD_ERC20_PAGE_BUTTON_ADD_LOGO")))]),_c('input',{attrs:{"type":"file","name":"icon","id":"images"},on:{"change":_vm.uploadImage}})]),_c('p',{staticClass:"label-file-support"},[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_LABEL_FILE_SUPPORT")))])]),(_vm.imageError===true)?_c('p',{staticClass:"msg-err-img"},[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_IMAGE_OVER_SIZE")))]):_vm._e()]),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_NAME'),"rules":"required|max:20"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_TOKEN_NAME'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_NAME'),"horizontal":""},model:{value:(_vm.platform.name),callback:function ($$v) {_vm.$set(_vm.platform, "name", $$v)},expression:"platform.name"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_CODE'),"rules":"required|max:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_TOKEN_CODE'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_CODE'),"horizontal":""},model:{value:(_vm.platform.symbol),callback:function ($$v) {_vm.$set(_vm.platform, "symbol", $$v)},expression:"platform.symbol"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_DECIMAL'),"rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_TOKEN_DECIMAL'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_DECIMAL'),"type":"number","horizontal":""},model:{value:(_vm.platform.decimal),callback:function ($$v) {_vm.$set(_vm.platform, "decimal", $$v)},expression:"platform.decimal"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_TOKEN_ADDRESS'),"rules":_vm.getRules()},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_TOKEN_ADDRESS'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_TOKEN_ADDRESS'),"horizontal":""},model:{value:(_vm.platform.sc_token_address),callback:function ($$v) {_vm.$set(_vm.platform, "sc_token_address", $$v)},expression:"platform.sc_token_address"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_VALIDATOR_FEE'),"rules":"required|min_value:0|max_value:1000"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom est-per",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_VALIDATOR_FEE'),"placeholder":"0","type":"number","horizontal":""},model:{value:(_vm.platform.erc20_validator_fee),callback:function ($$v) {_vm.$set(_vm.platform, "erc20_validator_fee", $$v)},expression:"platform.erc20_validator_fee"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_EST_REWARD'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom sec-label",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_REWARD'),"placeholder":"0","horizontal":""},model:{value:(_vm.platform.erc20_reward_estimate),callback:function ($$v) {_vm.$set(_vm.platform, "erc20_reward_estimate", $$v)},expression:"platform.erc20_reward_estimate"}}),_c('label',{staticClass:"label-display-only col-sm-3"},[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_LABEL_DISPLAY_ONLY")))]),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_DURATION'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom sec-label",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_DURATION'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_DURATION'),"horizontal":""},model:{value:(_vm.platform.erc20_duration),callback:function ($$v) {_vm.$set(_vm.platform, "erc20_duration", $$v)},expression:"platform.erc20_duration"}}),_c('label',{staticClass:"label-display-only col-sm-3"},[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_LABEL_DISPLAY_ONLY")))]),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_MAX_PAYOUT'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',[_c('CInput',{staticClass:"form-group--custom",attrs:{"label":_vm.$t('ADD_ERC20_PAGE_LABEL_MAX_PAYOUT'),"placeholder":_vm.$t('ADD_ERC20_PAGE_PLACEHOLDER_MAX_PAYOUT'),"horizontal":""},model:{value:(_vm.platform.max_payout),callback:function ($$v) {_vm.$set(_vm.platform, "max_payout", $$v)},expression:"platform.max_payout"}}),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)],1)]}}])}),_c('ValidationProvider',{attrs:{"name":_vm.$t('ADD_ERC20_PAGE_FIELD_NAME_VALIDATE_STATUS'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"select-wrap form-group"},[_c('label',[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_LABEL_STATUS")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.platform.status),expression:"platform.status"}],staticClass:"form-control role-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.platform, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.status),function(item){return _c('option',{key:item,domProps:{"value":item}},[_vm._v(" "+_vm._s(_vm.$t(("ADD_ERC20_PAGE_OPTION_SELECT_" + (_vm.renderStatus(item)))))+" ")])}),0)]),_c('CRow',[_c('CCol',{attrs:{"sm":{offset:'3'}}}),_c('CCol',{attrs:{"sm":9}},[_c('p',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('div',{staticClass:"btn-ERC20"},[_c('CButton',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t("ADD_ERC20_PAGE_BUTTON_SAVE")))])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }